import * as React from 'react';
import { FC } from 'react';

const SectionHero: FC<{ data: any }> = ({ data }) => {
  return (
    <section className="hero is-primary is-medium legal-hero">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column is-6">
              <strong className="has-text-white">{data.small}</strong>
              <h1 className="title">{data.title}</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { SectionHero };
