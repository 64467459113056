import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedPageProps } from '../utils/types';

import { SEO } from '../components/seo';
import { Layout } from '../components/partials/common';
import { SectionHero } from '../components/partials/legal/section-hero';
import { SectionContentTerms } from '../components/partials/legal/section-content';

const LegalPage: FC<LocalizedPageProps> = ({ pageContext }) => {
  const localKey = `${pageContext.key}`;
  const { lang, alternateUrls } = pageContext;
  const { t } = useTranslation('legal');

  const content: any = t(`${localKey}.content`, {
    returnObjects: true,
  });
  const hero: any = t(`${localKey}.hero`, {
    returnObjects: true,
  });
  const meta: any = t(`${localKey}.meta`, {
    returnObjects: true,
  });
  return (
    <Layout pageContext={pageContext}>
      <SEO
        lang={lang}
        title={meta.title}
        description={meta.description}
        alternateUrls={alternateUrls}
      />
      <SectionHero data={hero} />
      <SectionContentTerms content={content} />
    </Layout>
  );
};

export default LegalPage;
