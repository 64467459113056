/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-danger */
/* eslint-disable no-restricted-syntax */
import * as React from 'react';
import { FC } from 'react';

const SectionContentTerms: FC<{ content: any }> = ({ content }) => {
  return (
    <section className="section is-medium legal-section">
      <div className="container">
        <div className="columns">
          <div className="column is-9 content">
            {Object.keys(content).map((k) => {
              for (const [key, value] of Object.entries(content[k])) {
                if (key === 'h2')
                  return (
                    <h2
                      key={k}
                      dangerouslySetInnerHTML={{ __html: `${value}` }}
                    />
                  );
                if (key === 'h3')
                  return (
                    <h3
                      key={k}
                      dangerouslySetInnerHTML={{
                        __html: `${value}`,
                      }}
                    />
                  );
                if (key === 'h5')
                  return (
                    <h5
                      key={k}
                      dangerouslySetInnerHTML={{
                        __html: `${value}`,
                      }}
                    />
                  );
                if (key === 'h4')
                  return (
                    <h4
                      key={k}
                      dangerouslySetInnerHTML={{
                        __html: `${value}`,
                      }}
                    />
                  );
                if (key === 'h3')
                  return (
                    <h5
                      key={k}
                      dangerouslySetInnerHTML={{
                        __html: `${value}`,
                      }}
                    />
                  );
                if (key === 'ul')
                  return (
                    <ul
                      key={k}
                      dangerouslySetInnerHTML={{
                        __html: `${value}`,
                      }}
                    />
                  );
                if (key === 'div')
                  return (
                    <div
                      key={k}
                      dangerouslySetInnerHTML={{
                        __html: `${value}`,
                      }}
                    />
                  );
                if (key === 'p')
                  return (
                    <p
                      key={k}
                      dangerouslySetInnerHTML={{ __html: `${value}` }}
                    />
                  );

                if (key === 'uc')
                  return (
                    <div
                      className="uc-embed"
                      uc-layout="privacySettings"
                      uc-data="all"
                    />
                  );
              }
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export { SectionContentTerms };
